import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { BackendResponse, Company, Token, User } from '../model/models';
import { Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';
const Swal = require('sweetalert2')

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private apiUrl = 'https://dev.ionsapp.com/api/v1';
  // private apiUrl = 'http://localhost:8080/api/v1';
  private readonly local = inject(LocalStorageService);

  private userListener = new BehaviorSubject<Partial<User>>({});
  private companyListener = new BehaviorSubject<Partial<Company>>({});

  accessToken = new BehaviorSubject<string>("");
  refreshToken = new BehaviorSubject<string>("");
  
  constructor(private http: HttpClient, public router: Router) {}

  public get(endpoint: string): Observable<any> {
    const url = `${this.apiUrl}/${endpoint}`;
    return this.http.get(url);
  }

  public post(endpoint: string, data: any): Observable<any> {
    const url = `${this.apiUrl}/${endpoint}`;
    return this.http.post(url, data);
  }

  async login(data: { password: string, email: string }) {
    console.log(data)
    try {
      const resp = await firstValueFrom(
        this.http.post<Token & { user: User }>(`${this.apiUrl}/auth/login`, data)
      );
      this.loginWithToken(resp);
      if (resp.user.type !== "enterprise") {
      } else {
        this.loginWithToken(resp);
      }
    } catch (e) {
      this.showError()
      console.log(e);
    }
  }

  loginWithToken(resp: any) {
    if (resp.accessToken && resp.refreshToken) {
      this.accessToken.next(resp.accessToken);
      this.refreshToken.next(resp.refreshToken);
      this.saveToken(resp.accessToken, resp.refreshToken);
      this.userListener.next(resp.user);
      this.companyListener.next(resp.user.enterpriseUser?.company ?? {});
      this.saveUser(resp.user);
      this.saveCompany(resp.user.enterpriseUser?.company ?? {});
      this.router.navigate(["dashboard/default"]);

      // if (resp.user.enterpriseUser?.company) {
      //   this.router.navigate(["dashboard/default"]);
      // } else {
      //   this.router.navigate(["company"]);
      // }
    }
  }

  saveToken(accessToken: string, refreshToken: string) {
    this.local.saveData("access", accessToken);
    if (refreshToken) this.local.saveData("refresh", refreshToken);
    // this.local.saveData("expiresIn", `${expiresIn}`);
  }
  saveUser(data: Partial<User>) {
    this.local.saveData("user", JSON.stringify(data));
  }
  saveCompany(data: Partial<Company>) {
    this.local.saveData("company", JSON.stringify(data));
  }
  getToken() {
    return this.accessToken.value;
  }

  showError() {
    let timerInterval
    Swal.fire({
      title: 'Email or Password wrong!',
      // html: 'I will close in <strong></strong> seconds.',
      timer: 2000,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          Swal.getContent().querySelector('strong')
            .textContent = Swal.getTimerLeft()
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
      }
    }).then((result) => {
      if (
        // Read more about handling dismissals
        result.dismiss === Swal.DismissReason.timer
      ) {
      }
    })
  }

  getUsers(page:number = 1, limit:number =1, status, name, type, verification) {
    return this.http.get<BackendResponse<any>>(`${this.apiUrl}/users?limit=${limit}&page=${page}&status=${status}&name=${name}&type=${type}&verification=${verification}`);
  }

  getVerifications(page:number = 1, limit:number =1, status, name, uId) {
    return this.http.get<BackendResponse<any>>(`${this.apiUrl}/user/verifications/admin/list?limit=${limit}&page=${page}&status=${status}&name=${name}&uId=${uId}`);
  }

  putVerifications(id: number, data: any) {
    const apiUrl = `${this.apiUrl}/user/verifications/${id}`;
    return this.http.put<BackendResponse<any>>(apiUrl, data);
  }
  
  getCompanies(page:number = 1, limit:number =1, status, name, verification) {
    return this.http.get<BackendResponse<any>>(`${this.apiUrl}/companies?limit=${limit}&page=${page}&status=${status}&name=${name}&verification=${verification}`);
  }

  getCompanyVerifications(page:number = 1, limit:number =1, status, name, uId) {
    return this.http.get<BackendResponse<any>>(`${this.apiUrl}/company/verifications/admin/list?limit=${limit}&page=${page}&status=${status}&name=${name}&uId=${uId}`);
  }

  putCompanyVerifications(id: number, data: any) {
    const apiUrl = `${this.apiUrl}/company/verifications/${id}`;
    return this.http.put<BackendResponse<any>>(apiUrl, data);
  }
  getCommonTasks(page:number = 1, limit:number =1, status, name, level, code, platform) {
    if(status=='section'){
      status = '';
    }
    if(level=='level'){
      level = '';
    }
    if(platform=='platform'){
      platform = '';
    }
    return this.http.get<BackendResponse<any>>(`${this.apiUrl}/tasks/common/admin?limit=${limit}&page=${page}&section=${status}&title=${name}&level=${level}&code=${code}&platform=${platform}`);
  }

  putCommonTasks(id: number, data: any) {
    const apiUrl = `${this.apiUrl}/tasks/${id}`;
    return this.http.put<BackendResponse<any>>(apiUrl, data);
  }

  deleteCommonTasks(id: number) {
    const apiUrl = `${this.apiUrl}/tasks/${id}`;
    return this.http.delete<BackendResponse<any>>(apiUrl);
  }

  postCommonTasks(data: any) {
    const apiUrl = `${this.apiUrl}/tasks`;
    return this.http.post<BackendResponse<any>>(apiUrl, data);
  }

  public uploadFile(formData: FormData): Observable<any> {
    const url = `${this.apiUrl}/upload/task`;
    return this.http.post(url, formData);
  }


  getGlobalTags(page:number = 1, limit:number =1, category, name, level, code, platform) {
    if(category=='category'){
      category = '';
    }
    if(level=='level'){
      level = '';
    }
    if(platform=='platform'){
      platform = '';
    }
    return this.http.get<BackendResponse<any>>(`${this.apiUrl}/tags?limit=${limit}&page=${page}&category=${category}&title=${name}&level=${level}&code=${code}&platform=${platform}`);
  }

  putGlobalTags(id: number, data: any) {
    const apiUrl = `${this.apiUrl}/tags/${id}`;
    return this.http.put<BackendResponse<any>>(apiUrl, data);
  }

  deleteGlobalTags(id: number) {
    const apiUrl = `${this.apiUrl}/tags/${id}`;
    return this.http.delete<BackendResponse<any>>(apiUrl);
  }

  postGlobalTags(data: any) {
    const apiUrl = `${this.apiUrl}/tags`;
    return this.http.post<BackendResponse<any>>(apiUrl, data);
  }

  public uploadFileCommon(formData: FormData): Observable<any> {
    const url = `${this.apiUrl}/upload/common`;
    return this.http.post(url, formData);
  }
}