import { isPlatformBrowser } from "@angular/common";
import { inject, Injectable, PLATFORM_ID } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LocalStorageService {
  private platformId = inject(PLATFORM_ID);

  isBrowser = false;

  constructor() {
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
    }
  }

  saveData(key: string, value: string) {
    if (this.isBrowser) localStorage.setItem(key, value);
  }

  getData(key: string) {
    if (this.isBrowser) return localStorage.getItem(key);
    return undefined;
  }
  removeData(key: string) {
    if (this.isBrowser) localStorage.removeItem(key);
  }

  clearData() {
    if (this.isBrowser) localStorage.clear();
  }
}
