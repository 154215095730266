import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from "@angular/common/http";
import { inject } from "@angular/core";
import { Router } from "@angular/router";

import { catchError, Observable, throwError } from "rxjs";

export const ErrorInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const router = inject(Router);
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.error?.statusCode === 498) {
        // authservice.refresh();
        const cloned = req.clone();
        return next(cloned);
      } else {
        let message = "Error";
        if (error.error.message) {
          if (
            error.error.message === "The user is pending verification by the admin." ||
            error.error.message === "The user is blocked."
          ) {
            router.navigate(["soon"]);
          } else {
            message = error.error.message;
          }
        }
        return throwError(() => new Error(message));
      }
    })
  );
};
